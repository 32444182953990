
import HeaderPage from "@/view/content/widgets/engage/HeaderPage.vue";
import { defineComponent, onMounted, ref } from "vue";
import { StepperComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import { setLocale } from "yup";
import { pt } from "yup-locale-pt";
import { useField, useForm } from "vee-validate";
import { useRouter } from "vue-router";
import { Auth, Hub, API, graphqlOperation } from "aws-amplify";
import { listProfileFeatures } from "@/graphql/queries";
import { updateProfileFeature } from "@/graphql/mutations";
import { createEnterpriseFeature } from "@/core/database/data/enterprise/enterprise-graphql-mutations-operations.js";
import { createProfileFeature } from "@/core/database/data/profile/profile-graphql-mutations-operations.js";
import slugify from "slugify";
import { v4 as uuidv4 } from "uuid";

import { linkEnterpriseFeatureWithProfile } from "@/core/database/data/enterprise/enterprise-graphql-mutations-operations.js";

setLocale(pt);

interface KTWizard {
  sector: number;
  companyName: string;
  bio: string;
  yourName: string;
  password: string;
  signupPhone: string;
  confirmSignupPhone: number;
  leadRelationship: number;
  onlyOnlineBussiness: boolean;
  cep: string;
  addressLine: string;
  addressNumber: string;
  city: string;
  country: string;
  instagram: string;
  facebook: string;
  youtube: string;
  tiktok: string;
  twitter: string;
  whatsapp: string;
  name: string;
  email: string;
  phone: string;
  website: string;
  nameOnCard: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCVVNumber: string;
}

export default defineComponent({
  name: "Seja Membro",
  components: {
    HeaderPage,
  },
  setup() {
    const title = "Inovação, gestão e mercado";
    const subtitle =
      "<p>É um momento desafiador, exige criatividade, resiliência, muito empenho e união.</p>" +
      "<p>Cadastre seu negócio e faça parte de uma comunidade vibrante e empreendedora.</p>" +
      "<p>Aproveite as oportunidades de networking, eventos exclusivos, recursos de suporte ao empreendedorismo e amplie sua visibilidade no cenário local.</p>" +
      "<p>Este é o lugar para conectar-se com empreendedores, descobrir tendências e impulsionar o crescimento do seu negócio.</p>" +
      "<p>Junte-se a nós e faça parte dessa rede de inovação e sucesso.</p>";
    const search = "";

    const router = useRouter();

    const stepperRef = ref<HTMLElement | null>(null);

    // Create a form validation schema
    const wizardSchema = {
      sector: Yup.string()
        .required()
        .label("Informe em qual segmento o seu negócio atua. Este campo "),
      leadRelationship: Yup.string()
        .required()
        .label(
          "Informe em qual seu relacionamento com este negócio. Este campo "
        ),
      companyName: Yup.string()
        .required()
        .label("O nome da empresa deve ser preenchido. Este campo "),
      yourName: Yup.string()
        .required()
        .label("Nos diga o seu nome. Este campo "),
      bio: Yup.string()
        .required()
        .label("Nos conte um pouco sobre o seu negócio. Este campo "),
      email: Yup.string()
        .required()
        .email()
        .label("Informe o email da empresa. Este campo "),
      website: Yup.string()
        .nullable()
        .label(
          "Informe o site da empresa. Exemplo: www.seunegocio.com.br. Este campo "
        ),
      phone: Yup.string().matches(
        /([0-9]{5})([\\-])?([0-9]{4})/,
        "Exemplo de telefone válido: 9XXXX-XXXX"
      ),
      whatsapp: Yup.string()
        .required()
        .label("Informe o número de whatsapp. Este campo ")
        .matches(
          /([0-9]{5})([\\-])?([0-9]{4})/,
          "Exemplo de telefone válido: 9XXXX-XXXX"
        ),
      signupPhone: Yup.string()
        .required()
        .label("Informe o número de whatsapp. Este campo ")
        .matches(
          /([0-9]{5})([\\-])?([0-9]{4})/,
          "Exemplo de telefone válido: 9XXXX-XXXX"
        ),
    };

    // Create a form context with the validation schema
    useForm({
      validationSchema: wizardSchema,
    });

    const sectors = ref([
      {
        id: 1,
        label: "Alimentação e Bebidas",
      },
      {
        id: 2,
        label: "Agronegócio",
      },
      {
        id: 3,
        label: "Automotivo",
      },
      {
        id: 4,
        label: "Construção",
      },
      {
        id: 5,
        label: "Conteúdo digital",
      },
      {
        id: 6,
        label: "Economia Verde",
      },
      {
        id: 7,
        label: "Educação",
      },
      {
        id: 8,
        label: "Entretenimento",
      },
      {
        id: 9,
        label: "Informática",
      },
      {
        id: 10,
        label: "Máquinas e Equipamentos",
      },
      {
        id: 11,
        label: "Mercado Imobiliário",
      },
      {
        id: 12,
        label: "Pesquisa e Desenvolvimento",
      },
      {
        id: 13,
        label: "Pets",
      },
      {
        id: 14,
        label: "Saúde & Bem-Estar",
      },
      {
        id: 15,
        label: "Serviços Especializados",
      },
      {
        id: 16,
        label: "Serviços Pessoais",
      },
      {
        id: 17,
        label: "Serviços Financeiros",
      },
      {
        id: 18,
        label: "Vestuário e Calçados",
      },
      {
        id: 19,
        label: "Vendas e marketing",
      },
      {
        id: 20,
        label: "Tecnologia da Informação e Comunicação",
      },
      {
        id: 21,
        label: "Outros",
      },
    ]);

    const leadRelationshipList = ref([
      {
        id: 1,
        label: "Proprietário",
      },
      {
        id: 2,
        label: "Funcionário",
      },
      {
        id: 3,
        label: "Consultor",
      },
      {
        id: 4,
        label: "Parceiro de Negócios",
      },
      {
        id: 5,
        label: "Investidor",
      },
      {
        id: 6,
        label: "Fornecedor",
      },
      {
        id: 7,
        label: "Interessado/Estudante/Estagiário",
      },
      {
        id: 8,
        label: "Cliente",
      },
      {
        id: 9,
        label: "Mídia/Imprensa",
      },
      {
        id: 10,
        label: "Não tenho relação direta",
      },
    ]);

    const data = ref<KTWizard>({
      sector: 0,
      companyName: "",
      bio: "",
      yourName: "",
      password: "",
      signupPhone: "",
      confirmSignupPhone: 0,
      leadRelationship: 0,
      onlyOnlineBussiness: false,
      cep: "",
      addressLine: "",
      addressNumber: "",
      city: "",
      country: "Brasil",
      instagram: "",
      facebook: "",
      youtube: "",
      tiktok: "",
      twitter: "",
      whatsapp: "",
      name: "",
      email: "",
      phone: "",
      website: "",
      nameOnCard: "",
      cardNumber: "",
      cardExpiryMonth: "",
      cardExpiryYear: "",
      cardCVVNumber: "",
    });

    const {
      value: sector,
      errorMessage: sectorError,
      validate: validateSector,
    } = useField("sector");
    const {
      value: leadRelationship,
      errorMessage: leadRelationshipError,
      validate: validateLeadRelationship,
    } = useField("leadRelationship");
    const {
      value: companyName,
      errorMessage: companyNameError,
      validate: validateCompanyName,
    } = useField("companyName");
    const {
      value: yourName,
      errorMessage: yourNameError,
      validate: validateYourName,
    } = useField("yourName");
    const {
      value: signupPhone,
      errorMessage: signupPhoneError,
      validate: validateSignupPhone,
    } = useField("signupPhone");
    const {
      value: confirmSignupPhone,
      errorMessage: confirmSignupPhoneError,
      validate: validateConfirmSignupPhone,
    } = useField("confirmSignupPhone");
    const {
      value: bio,
      errorMessage: bioError,
      validate: validateBio,
    } = useField("bio");
    const {
      value: email,
      errorMessage: emailError,
      validate: validateEmail,
    } = useField("email");
    const {
      value: website,
      errorMessage: websiteError,
      validate: validateWebsite,
    } = useField("website");
    const {
      value: phone,
      errorMessage: phoneError,
      validate: validatePhone,
    } = useField("phone");
    const {
      value: whatsapp,
      errorMessage: whatsappError,
      validate: validateWhatsapp,
    } = useField("whatsapp");

    const _stepperObj = ref<StepperComponent | null>(null);

    onMounted(() => {
      // global.window.analytics.page("Signup Business Member");
      if (!stepperRef.value) {
        return;
      }

      _stepperObj.value = StepperComponent.createInstance(stepperRef.value);
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Parceiros", "Faça Parte");
    });

    const nextStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      if (_stepperObj.value.getCurrentStepIndex() === 1) {
        data.value.sector = sector.value as number;
        data.value.companyName = companyName.value as string;
        data.value.bio = bio.value as string;

        validateSector().then((result) => {
          validateCompanyName().then((result1) => {
            validateBio().then((result3) => {
              if (result.valid && result1.valid && result3.valid) {
                if (!_stepperObj.value) {
                  return;
                }

                _stepperObj.value.goNext();
              } else {
                Swal.fire({
                  text: "Queremos entender melhor como podemos ajudar a impulsionar o seu negócio. Por favor, revise e complete suas informações!",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, vou revisar!",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              }
            });
          });
        });
        return;
      }

      if (_stepperObj.value.getCurrentStepIndex() === 3) {
        data.value.email = email.value as string;
        data.value.website = ("https://" + website.value) as string;
        data.value.phone = ("+55" + phone.value) as string;
        data.value.whatsapp = ("+55" + whatsapp.value) as string;

        validateEmail().then((result) => {
          validatePhone().then((result1) => {
            validateWebsite().then((result2) => {
              validateWhatsapp().then((result3) => {
                if (
                  result.valid &&
                  result1.valid &&
                  result2.valid &&
                  result3.valid
                ) {
                  if (!_stepperObj.value) {
                    return;
                  }
                  _stepperObj.value.goNext();
                } else {
                  Swal.fire({
                    text: "Indique os meios pelos quais os clientes e parceiros podem entrar em contato com sua empresa. Por favor, complete suas informações!",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, vou revisar!",
                    customClass: {
                      confirmButton: "btn fw-bold btn-light-danger",
                    },
                  });
                }
              });
            });
          });
        });
        return;
      }

      if (_stepperObj.value.getCurrentStepIndex() === 5) {
        _stepperObj.value.goNext();
      }

      if (_stepperObj.value.getCurrentStepIndex() === 6) {
        data.value.yourName = yourName.value as string;
        data.value.leadRelationship = leadRelationship.value as number;

        validateLeadRelationship().then((result) => {
          if (result.valid) {
            if (!_stepperObj.value) {
              return;
            }

            try {
              Auth.currentAuthenticatedUser().then((user) => {
                const slugnameBusiness = slugify(
                  data.value.companyName,
                  "."
                ).toLowerCase();

                var normalizeData = {
                  name: data.value.companyName,
                  url: data.value.website,
                  slugname: slugnameBusiness,
                  metadata: {
                    bio: data.value.bio,
                    sector: JSON.stringify(
                      sectors.value.filter((x) => x.id == data.value.sector)
                    ),
                    address: {
                      onlyOnlineBussiness: data.value.onlyOnlineBussiness,
                      cep: data.value.cep,
                      addressLine: data.value.addressLine,
                      addressNumber: data.value.addressNumber,
                      city: data.value.city,
                      country: data.value.country,
                    },
                    social_network: {
                      instagram: data.value.instagram,
                      facebook: data.value.facebook,
                      youtube: data.value.youtube,
                      tiktok: data.value.tiktok,
                      twitter: data.value.twitter,
                    },
                    channels: {
                      phone: data.value.phone,
                      whatsapp: data.value.whatsapp,
                      email: data.value.email,
                      website: data.value.website,
                    },
                    owner: {
                      username: null,
                      displayName: null,
                      signupPhone: user.attributes.phone_number,
                      leadRelationship: JSON.stringify(
                        leadRelationshipList.value.filter(
                          (x) => x.id == data.value.leadRelationship
                        )
                      ),
                    },
                  },
                  enabled: true,
                  verified: false,
                };

                linkEnterpriseFeatureWithProfile(user.username, normalizeData);
              });
            } catch (error) {
              console.log("error signing up:", error);
            }

            _stepperObj.value.goNext();
          } else {
            Swal.fire({
              text: "Essas informações são importantes para garantir a melhor experiência em nossa comunidade. Por favor, revise e complete seus dados!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, vou revisar!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          }
        });
      }

      _stepperObj.value.goNext();
    };

    const prevStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goPrev();
    };

    const formSubmit = () => {
      data.value.confirmSignupPhone = confirmSignupPhone.value as number;

      validateConfirmSignupPhone().then((result) => {
        if (result.valid) {
          if (!_stepperObj.value) {
            return;
          }

          try {
            Auth.confirmSignUp(
              data.value.signupPhone,
              data.value.confirmSignupPhone.toString()
            );
            router.push("/home");
          } catch (error) {
            console.log("error confirming sign up", error);
          }

          _stepperObj.value.goNext();
        }
      });

      return;
    };

    return {
      title,
      subtitle,
      search,
      sectors,
      sector,
      sectorError,
      companyName,
      companyNameError,
      yourName,
      yourNameError,
      signupPhone,
      signupPhoneError,
      confirmSignupPhone,
      confirmSignupPhoneError,
      bio,
      bioError,
      email,
      emailError,
      website,
      websiteError,
      phone,
      phoneError,
      whatsapp,
      whatsappError,
      data,
      formSubmit,
      nextStep,
      prevStep,
      stepperRef,
      leadRelationshipList,
      leadRelationship,
      leadRelationshipError,
    };
  },
  mounted() {
    this.loadProfile();
  },
  data() {
    return {
      authenticatedUser: {
        username: "",
      },
      currentProfile: {},
    };
  },
  methods: {
    async loadProfile() {
      await Auth.currentAuthenticatedUser().then((user) => {
        this.authenticatedUser = user;
      });

      if (this.authenticatedUser.username) {
        const response: any = await API.graphql(
          graphqlOperation(listProfileFeatures, {
            filter: {
              auth_signup_id: {
                eq: this.authenticatedUser.username,
              },
              enabled: {
                eq: true,
              },
            },
          })
        );
        if (response.data.listProfileFeatures.items.length > 0) {
          this.currentProfile = response.data.listProfileFeatures.items[0];
        }
      }
    },
    async resendConfirmationCode(signupPhone) {
      try {
        await Auth.resendSignUp(signupPhone);
        console.log("code resent successfully");
      } catch (err) {
        console.log("error resending code: ", err);
      }
    },
    listenToAutoSignInEvent() {
      Hub.listen("auth", ({ payload }) => {
        const { event } = payload;
        if (event === "autoSignIn") {
          const user = payload.data;
          console.log(user);
          // assign user
        } else if (event === "autoSignIn_failure") {
          // redirect to sign in page
          console.log("user auto signin failure");
        }
      });
    },
  },
});
